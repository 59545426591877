import React from 'react';
import { Global, ThemeProvider } from '@emotion/react';
import globalStyle from 'GlobalStyle';
import { theme } from 'theme';
import Home from 'pages/Home';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyle} />
      <Home />
    </ThemeProvider>
  );
};

export default App;
