import * as React from 'react';

function SvgArrow(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 50 50" fill="none" {...props}>
      <path
        d="M0 25c0 13.785 11.215 25 25 25s25-11.215 25-25S38.785 0 25 0 0 11.215 0 25zm29.903-10.111l9.375 9.375a1.041 1.041 0 010 1.472l-9.375 9.375a1.041 1.041 0 01-1.778-.736v-2.083c0-.277.11-.542.305-.737l4.472-4.472H11.458a1.041 1.041 0 01-1.041-1.041v-2.084c0-.576.466-1.041 1.041-1.041h21.444l-4.472-4.472a1.04 1.04 0 01-.305-.737v-2.083a1.04 1.04 0 011.778-.736z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgArrow;
