import React from 'react';
import PropTypes from 'prop-types';
import { TestimonialWrapper, Name, Role, DescriptionWrapper, Description } from './styled';

export const Testimonial = ({ description, name, role }) => {
  return (
    <TestimonialWrapper>
      <Name>{name}</Name>
      <Role>{role}</Role>
      <DescriptionWrapper>
        <Description>{description}</Description>
      </DescriptionWrapper>
    </TestimonialWrapper>
  );
};

Testimonial.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};
