import { projectImages } from 'lib/images';

export const projects = [
  {
    type: 'Web & Mobile',
    title: 'Sharply Africa',
    role: 'Founder and Head of product',
    img: projectImages.sharplyAfrica,
    link: {
      project: 'https://www.sharply.africa',
      caseStudy: 'https://www.notion.so/layor/Sharply-Delivery-Checkout-aea889ef00dc43aeb225d819f3180a3e?pvs=4',
    },
  },
  {
    type: 'Web',
    title: 'Indigo EMR',
    role: 'Design and Growth Lead',
    img: projectImages.indigo,
    link: {
      caseStudy: 'https://www.notion.so/layor/Indigo-V2-Patient-Visit-Redesign-e7589bef43e64a9bb36d9acf2e1af1f5?pvs=4',
      project: 'https://indigoemr.com',
    },
  },
  {
    type: 'Web',
    title: 'FlavorQueste',
    role: 'Head of product',
    img: projectImages.flavorqueste,
    link: {
      project: 'http://flavorqueste.com',
    },
  },
  {
    type: 'Mobile',
    title: 'Moniwave',
    role: 'Design and Product Manager',
    img: projectImages.moniwave,
    link: {
      caseStudy: 'https://www.notion.so/layor/Indigo-V2-Patient-Visit-Redesign-e7589bef43e64a9bb36d9acf2e1af1f5?pvs=4',
      project: 'https://indigoemr.com',
    },
  },
  {
    type: 'Web',
    title: 'Ogaranya',
    role: 'UI/UX Designer',
    img: projectImages.ogaranya,
    link: {
      project: 'https://www.ogaranya.com',
    },
  },
  {
    type: 'Mobile App',
    title: 'P2vest Loan App',
    role: 'UI/UX Designer',
    img: projectImages.p2vest,
    link: {
      project: 'https://apps.apple.com/ng/app/p2vest-lend-or-borrow/id1532393698',
    },
  },
  {
    type: 'Web App',
    title: 'FindMaids',
    role: 'UI/UX Designer',
    img: projectImages.findMaids,
    link: {
      project: 'https://findmaids.co.uk',
      caseStudy: 'https://medium.com/@salamilayor/delivering-findmaids-co-uk-case-study-4ee8e8c3d64f',
    },
  },
  {
    type: 'Web App',
    title: 'Spectranet Web Portal Redesign Concept',
    role: 'UI/UX Designer',
    img: projectImages.spectranet,
    link: {
      caseStudy:
        'https://medium.com/@salamilayor/redesigning-spectranet-self-care-landing-and-payment-page-879c143d6347',
    },
  },
];
