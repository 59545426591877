import React from 'react';
import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { Typography } from 'components/atoms';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 15rem;

  ${({ theme }) => theme.mq.lg`
  margin-bottom: 10rem;
  `}
`;

const Greeting = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.33;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mq.lg`
  margin-bottom: 1.5rem;
  font-size: 5.4rem;

`}
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 4.5rem;
  font-weight: bold;
  line-height: 1.33;
  margin-bottom: 2rem;
  max-width: 120rem;

  ${({ theme }) => theme.mq.lg`
  margin-bottom: 5.8rem;
  font-size: 7.4rem;
`}
`;

const Subtitle = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.1rem;
  font-weight: 300;

  ${({ theme }) => theme.mq.lg`
  font-size: 4.1rem;

`}
`;

export const Banner = () => {
  return (
    <Wrapper>
      <Greeting>Hey there,</Greeting>
      <Title>I am Layor, a product designer based in Lagos.</Title>
      <Subtitle>here to solve user problems with human centered designs</Subtitle>
    </Wrapper>
  );
};
