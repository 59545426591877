import React from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { SlideItemWrapper, SlideImage } from './styled';

export const SlideItem = ({ active, alt, src, carouselHeight }) => {
  const [ref, { width }] = useMeasure();

  return (
    <SlideItemWrapper ref={ref} height={active ? carouselHeight : width} active={active}>
      <SlideImage src={src} alt={alt} />
    </SlideItemWrapper>
  );
};

SlideItem.propTypes = {
  active: PropTypes.bool,
  alt: PropTypes.string.isRequired,
  carouselHeight: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
};

SlideItem.defaultProps = {
  active: false,
};
