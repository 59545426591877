/* eslint-disable global-require */
// a file for handling static images

export const projectImages = {
  digitalBank: require('assets/img/projects/digital-bank.jpg'),
  ebony: require('assets/img/projects/ebony.jpg'),
  findMaids: require('assets/img/projects/find-maids.jpg'),
  spectranet: require('assets/img/projects/spectranet.jpg'),
  p2vest: require('assets/img/projects/p2vest.jpg'),
  indigo: require('assets/img/projects/indigo.jpg'),
  moniwave: require('assets/img/projects/moniwave.jpg'),
  ogaranya: require('assets/img/projects/ogaranya.jpg'),
  sharplyAfrica: require('assets/img/projects/sharply-africa.jpg'),
  flavorqueste: require('assets/img/projects/flavorqueste.jpg'),
};

export const peopleImages = {
  dolapo: require('assets/img/people/dolapo.jpg'),
  remi: require('assets/img/people/remi.jpg'),
  tosin: require('assets/img/people/tosin.jpg'),
  tobi: require('assets/img/people/tobi.jpg'),
  zaga: require('assets/img/people/zaga.jpg'),
};
