import React, { memo } from 'react';
import styled from '@emotion/styled';
import { Content } from 'components/atoms';
import { Project } from 'components/molecules';
import { projects } from 'data/projects';

const Wrapper = styled.section`
  margin-bottom: 11rem;
`;

const Header = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;

  ${({ theme }) => theme.mq.lg`
  font-size: 48px;
  margin-bottom: 80px;
  `}
`;

const ProjectsList = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.lg`
    padding: 0 1.5rem;
  `}

  ${({ theme }) => theme.mq.xl`
    padding: 0;
  `}
`;

const Projects = memo((props) => {
  return (
    <Wrapper id="projects" {...props}>
      <Content>
        <Header>Recent Projects</Header>
        <ProjectsList>
          {projects.map((project, i) => (
            <Project key={project.title} {...project} order={i % 2} />
          ))}
        </ProjectsList>
      </Content>
    </Wrapper>
  );
});

export default Projects;
