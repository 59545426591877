import styled from '@emotion/styled/macro';
import { Box } from 'rebass';

export const Wrapper = styled.form`
  margin: 0;
  max-width: 70rem;
  padding: 0;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const Input = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: ${({ theme }) => theme.colors.white}17;
  border-radius: 0;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  display: block;
  font-size: 1.8rem;
  height: 5rem;
  line-height: 1.2;
  margin: 0;
  outline: none;
  padding: 0.5rem 2rem;
  width: 100%;

  &:disabled {
    background-color: rgba(128, 128, 128, 0.12);
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.white}29;
    font-size: 1.4rem;
  }
`;

export const Textarea = styled.textarea`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: ${({ theme }) => theme.colors.white}17;
  border-radius: 0;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  display: block;
  font-size: 1.8rem;
  line-height: 1.2;
  margin: 0;
  outline: none;
  padding: 2rem 2rem;
  width: 100%;

  &:disabled {
    background-color: rgba(128, 128, 128, 0.12);
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.white}29;
    font-size: 1.4rem;
  }
`;

export const Group = styled(Box)`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 3.5rem;
  }
`;
