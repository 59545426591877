import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { Typography } from 'components/atoms';

export const FooterWrapper = styled(Flex)`
  border-top: 5px solid ${({ theme }) => theme.colors.white};
  overflow: hidden;
  padding-top: 5rem;

  ${({ theme }) => theme.mq.md`
  padding-top: 10rem;
  `}
`;

export const FooterHeader = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.63;

  ${({ theme }) => theme.mq.sm`
  font-size: 3.8rem;
  `}

  ${({ theme }) => theme.mq.lg`
  font-size: 4.8rem;
  `}

  ${({ theme }) => theme.mq.xl`
  font-size: 5.8rem;
  `}
`;

export const Socials = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const SocialItem = styled.li`
  &:not(:last-child) {
    margin-right: 5rem;

    ${({ theme }) => theme.mq.md`
    margin-right: 3.5rem;
    `}

    ${({ theme }) => theme.mq.lg`
    margin-right: 7rem;
    `}
  }
`;

export const SocialLink = styled.a`
  svg {
    width: 2.5rem;
    height: 2.5rem;

    ${({ theme }) => theme.mq.md`
    width: 3.5rem;
    height: 3.5rem;
    `}
  }
`;

export const Copyright = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.63;
  max-width: 35rem;
  text-align: center;

  ${({ theme }) => theme.mq.md`
  font-size: 1.6rem;
  `}
`;

SocialLink.defaultProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};
