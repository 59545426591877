import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { animated } from 'react-spring';
import { Flex, Box } from 'rebass';

export const NavWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  left: 0;
  padding: 1.5rem 0;
  position: fixed;
  top: 0;
  transition: all 0.2s;
  width: 100%;
  z-index: 2;

  ${({ theme }) => theme.mq.lg`
  padding: 3rem 0;
  `}

  ${({ scrolled, theme }) =>
    scrolled &&
    css`
      padding: 0.5rem 0 !important;
      background-color: ${theme.colors.darkBlue};
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    `}
`;

export const NavBrand = styled(Box)`
  cursor: pointer;
  svg {
    height: 4.4rem;
    width: 12.2rem;

    ${({ theme }) => theme.mq.md`
      height: 6.4rem;
      width: 17.8rem;
  `}
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;

  ${({ theme }) => theme.mq.lg`
  align-items: center;
  flex-direction: row;
  margin-bottom: 0;
`}
`;

export const NavItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  ${({ theme }) => theme.mq.lg`
  
  &:not(:last-child){
    margin-bottom: 0;
    margin-right: 3rem;
  }
  `}
`;

export const NavLink = styled.a`
  &,
  &:visited {
    color: ${({ theme }) => theme.colors.white};
  }
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
`;

export const Hamburger = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 21px;
  outline: none;
  padding: 0;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 30px;

  span {
    background: ${({ theme }) => theme.colors.white};
    border-radius: 9px;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
    width: 100%;

    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 10px;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 19px;
      transform-origin: left center;
    }
  }

  ${({ active }) =>
    active &&
    css`
      span {
        &:nth-child(1) {
          transform: rotate(40deg);
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-40deg);
        }
      }
    `}

  ${({ theme }) => theme.mq.lg`
    display: none;
`}
`;

export const DesktopMenu = styled(Box)`
  display: none;

  ${({ theme }) => theme.mq.lg`
      display: flex;
`}
`;

export const MobileMenu = styled(animated.div)`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  height: 100vh;
  overflow: hidden;
  padding: 9rem 4rem;
  position: fixed;
  right: 0;
  top: 0;
  transform-origin: 100% 0%;
  width: 100vw;

  ${({ theme }) => theme.mq.lg`
      display: none;
`}
`;

export const DownloadButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.mq.lg`
  display: none;
  `}

  ${({ theme }) => theme.mq.xl`
  display: flex;
  justify-content: flex-start;
  `}
`;
