import * as React from 'react';

function SvgLinkedin(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 35 35" fill="none" {...props}>
      <path d="M31.834 0H3.166A3.166 3.166 0 000 3.166v28.668A3.166 3.166 0 003.166 35h28.668A3.166 3.166 0 0035 31.834V3.166A3.166 3.166 0 0031.834 0zM10.831 30.221a.921.921 0 01-.922.922H5.987a.921.921 0 01-.921-.922v-16.44c0-.51.412-.922.921-.922H9.91c.509 0 .921.412.921.921v16.441zM7.948 11.31a3.726 3.726 0 110-7.452 3.726 3.726 0 010 7.452zm23.379 18.986c0 .468-.38.848-.847.848H26.27a.847.847 0 01-.847-.848v-7.711c0-1.15.337-5.041-3.006-5.041-2.594 0-3.12 2.663-3.226 3.858v8.895c0 .467-.38.847-.847.847h-4.07a.847.847 0 01-.848-.848V13.707c0-.468.38-.847.847-.847h4.07c.469 0 .848.38.848.847v1.434c.962-1.443 2.391-2.557 5.434-2.557 6.74 0 6.7 6.296 6.7 9.755v7.957z" />
    </svg>
  );
}

SvgLinkedin.defaultProps = {
  fill: '#fff',
};

export default SvgLinkedin;
