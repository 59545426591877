import styled from '@emotion/styled';

export const Button = styled.button`
  -webkit-appearance: none;
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: inline-flex;
  height: 4.5rem;
  justify-content: center;
  line-height: 1;
  outline: none;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  white-space: nowrap;

  &:before {
    background-color: ${({ theme }) => theme.colors.white};
    content: '';
    height: 100%;
    left: 0;
    mix-blend-mode: difference;
    position: absolute;
    top: 0;
    transition: all 0.2s;
    width: 54%;
  }

  &:hover {
    &:before {
      left: 50%;
      perspective: 500px;
      transform: skewX(-20deg);
      width: calc(50% + 0.7rem);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
