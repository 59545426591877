import * as React from 'react';

function SvgPlay(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm4.148 12.636l-6 3.75A.75.75 0 019 15.75v-7.5a.75.75 0 011.148-.636l6 3.75a.75.75 0 010 1.272z" />
    </svg>
  );
}

export default SvgPlay;
