import React from 'react';
import { Button } from 'components/atoms';
import { Box, Flex } from 'rebass';
import { Group, Input, Label, Textarea, Wrapper } from './styled';

export const ContactForm = () => {
  return (
    <Wrapper as="form" action="https://formspree.io/meqrqkdb" method="POST">
      <Group>
        <Label htmlFor="form-projectType">What type of product do you want to build</Label>
        <Input required id="form-projectType" name="projectType" placeholder="Andriod, Web or Ios" />
      </Group>

      <Group>
        <Label htmlFor="form-description">Tell me about the product</Label>
        <Textarea required id="form-description" name="description" rows="5" placeholder="Type description here" />
      </Group>

      <Flex mx="-1.5rem" flexWrap="wrap">
        <Box px="1.5rem" width={[1, 1 / 2]} mb="3.5rem">
          <Group>
            <Label htmlFor="form-budget">Your budget</Label>
            <Input required id="form-budget" name="budget" placeholder="₦200k - ₦500k" />
          </Group>
        </Box>

        <Box px="1.5rem" width={[1, 1 / 2]} mb="3.5rem">
          <Group>
            <Label htmlFor="form-timeline">Your timeline</Label>
            <Input required id="form-timeline" name="timeline" placeholder="> 4 weeks" />
          </Group>
        </Box>
      </Flex>

      <Group>
        <Label htmlFor="form-email">Your email</Label>
        <Input required id="form-email" name="email" type="email" placeholder="john.doe@email.com" />
      </Group>

      <Group alignItems="flex-start">
        <Button>Let’s Talk</Button>
      </Group>
    </Wrapper>
  );
};
