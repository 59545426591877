import React, { useState, useRef } from 'react';
import Carousel from 'nuka-carousel';
import { createBreakpoint, useMeasure } from 'react-use';
import { Content } from 'components/atoms';
import { Flex, Box } from 'rebass';
import { testimonials } from 'data/testimonials';
import { IconArrow } from 'components/icons';
import { Wrapper, Arrows } from './styled';
import { Testimonial } from './Testimonial';
import { SlideItem } from './SlideItem';

export const sliderItems = createBreakpoint({
  3: 1200,
  2: 992,
  1: 0,
});

const Testimonials = () => {
  const itemCount = parseInt(sliderItems(), 10);
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef();
  const [carouselWrapper, { height }] = useMeasure();

  const onSlideChange = (newIndex) => {
    setSlideIndex(newIndex);
  };

  const nextSlide = () => {
    if (sliderRef?.current) sliderRef.current.previousSlide();
  };

  const prevSlide = () => {
    if (sliderRef?.current) sliderRef.current.nextSlide();
  };

  return (
    <Wrapper id="testimonials">
      <Content>
        <Flex mx={['-0.5rem', null, '-3rem']}>
          <Box
            ref={carouselWrapper}
            alignItems="stretch"
            display={['none', 'flex']}
            flex={[null, null, null, 1]}
            flexBasis={['10rem', '30%', '40%', 'unset']}
            px={['0.5rem', null, '3rem']}
            width={['1.5rem', null, 'unset']}
          >
            <Carousel
              afterSlide={onSlideChange}
              autoplay={false}
              cellAlign="right"
              cellSpacing={10}
              className="carousel"
              dragging
              enableKeyboardControls
              initialSlideHeight={height}
              ref={sliderRef}
              scrollMode="remainder"
              slideIndex={slideIndex}
              slidesToShow={itemCount}
              withoutControls
              wrapAround
            >
              {testimonials.map((item, index) => (
                <SlideItem
                  alt={item.name}
                  key={item.id}
                  carouselHeight={height}
                  src={item.img}
                  active={slideIndex === index}
                />
              ))}
            </Carousel>
          </Box>
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            maxWidth={[null, null, '44rem']}
            px={['0.5rem', null, '3rem']}
          >
            <Testimonial {...testimonials[slideIndex]} />
            <Arrows>
              <IconArrow className="r" onClick={prevSlide} />

              <IconArrow onClick={nextSlide} />
            </Arrows>
          </Box>
        </Flex>
      </Content>
    </Wrapper>
  );
};

export default Testimonials;
