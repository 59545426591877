import { peopleImages } from 'lib/images';

export const testimonials = [
  {
    name: 'Emmanuel (ZAGA™️) Obidiegwu',
    role: 'Software Engineer, Google',
    img: peopleImages.zaga,
    description: `Mr Layor is very diligent and detail oriented UI/UX designer. I have worked with him across different types of projects and he always exceeds expectations by going above and beyond to ensure that any design he produces addresses the customer's needs, makes the user journey efficient and reasonable to implement by engineers.`,
    id: 'zaga',
  },
  {
    name: 'Remilekun Salami',
    role: 'Front-End Developer',
    img: peopleImages.remi,
    description:
      'I have worked with Layor on various projects and I just have to commend his whole design process, he is very thorough with it hence making the project amazing to work on and easy to use.',
    id: 'casper',
  },
  {
    name: 'Tosin Orimogunje',
    role: 'Front-End Developer and Designer, Flutterwave',
    img: peopleImages.tosin,
    description:
      'When you work with Layor, you get his vast knowledge of user-centered design, product management, and quality assurance. I worked with him at Summitech, where I watched him take on each of these roles with great enthusiasm and passion, and also deliver excellently. Working with him is a delight, as he is vast with his tools, a great listener, and communicates excellently.',
    id: 'reemtos',
  },
  {
    name: 'Tobi Amodu',
    role: 'Product Designer at TalentUp Africa',
    img: peopleImages.tobi,
    description:
      'Layor’s story is an inspiration. Watching him evolve into the product designer that he is today is a thing of joy. We have worked together on multiple projects. His knowledge as a QA analyst comes to the fore as he pays close attention to details and always puts the user foremost in his process. Always full of live and energy, Layor is definitely the guy you want to colloborate with on your next project.',
    id: 'tobamodu',
  },
  {
    name: 'Dolapo Omidire',
    role: 'Founder, CEO Estate Intel',
    img: peopleImages.dolapo,
    description: `Titi is a committed Project Manager that is focused on doing everything that is required to deliver for his client. For a few months he supported the delivery of many features and the achievement of major product milestones at Estate Intel. \n 
      He is young, energetic and committed to his growth and will do his best to deliver. `,
    id: 'dollyp',
  },
];
