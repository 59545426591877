import * as React from 'react';

function SvgTwitter(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 35 29" fill="none" {...props}>
      <path d="M35 3.649c-1.288.57-2.67.958-4.123 1.13A7.21 7.21 0 0034.033.81a14.476 14.476 0 01-4.562 1.743 7.16 7.16 0 00-5.24-2.268A7.18 7.18 0 0017.236 9.1C11.27 8.8 5.98 5.941 2.44 1.597a7.125 7.125 0 00-.971 3.61A7.176 7.176 0 004.66 11.18a7.18 7.18 0 01-3.252-.9v.089a7.187 7.187 0 005.758 7.04 7.27 7.27 0 01-1.892.252c-.463 0-.912-.046-1.351-.134a7.184 7.184 0 006.705 4.987 14.405 14.405 0 01-8.916 3.068c-.58 0-1.15-.035-1.713-.098a20.295 20.295 0 0011.005 3.23c13.208 0 20.428-10.94 20.428-20.427l-.024-.93A14.336 14.336 0 0035 3.648z" />
    </svg>
  );
}

SvgTwitter.defaultProps = {
  fill: '#fff',
};

export default SvgTwitter;
