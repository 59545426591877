import styled from '@emotion/styled';
import { Box } from 'rebass';
import { Typography, Image } from 'components/atoms';

export const Wrapper = styled.section`
  margin-bottom: 11rem;
  .carousel {
    .slider-slide {
      align-items: flex-end;
      display: inline-flex !important;
    }
  }

  ${({ theme }) => theme.mq.md`
  margin-bottom: 20rem;
  
  `}
`;

export const Arrows = styled(Box)`
  margin-top: auto;

  svg {
    cursor: pointer;
    height: 5rem;
    width: 5rem;

    &.r {
      transform: rotate(180deg);
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const TestimonialWrapper = styled(Box)`
  margin-bottom: 2.5rem;
`;

export const Name = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.mq.lg`
  font-size: 1.8rem;
  `}
`;

export const Role = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 3rem;

  ${({ theme }) => theme.mq.lg`
  font-size: 1.6rem;
  `}
`;

export const DescriptionWrapper = styled(Box)`
  background-color: rgba(255, 255, 255, 0.09);
  padding: 2rem;
`;

export const Description = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  font-weight: 300;
  opacity: 0.5;

  ${({ theme }) => theme.mq.lg`
  font-size: 1.8rem;
  `}
`;

export const SlideImage = styled(Image)`
  height: 100%;
  width: 100%;
  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
`;

export const SlideItemWrapper = styled(Box)`
  height: 40rem;
  transition: all 0.2s;
  width: 100%;

  ${({ theme, height }) => theme.mq.lg`
        height: ${height}px;
        
`}
`;
