import React from 'react';
import styled from '@emotion/styled/macro';
import { Content, Typography } from 'components/atoms';
import { Section, ContactForm } from 'components/molecules';

const Wrapper = styled.div`
  margin-bottom: 13rem;

  ${({ theme }) => theme.mq.md`
  margin-bottom: 19rem;
  `}
`;

const ContactMe = () => {
  return (
    <Wrapper as="section" id="contact">
      <Content>
        <Section mb={['5rem', null, '7rem']}>
          <Section.Left>
            <Typography.Paragraph color="aquamarine" size="medium" weight="medium">
              Let&apos;s work together
            </Typography.Paragraph>
          </Section.Left>

          <Section.Right maxWidth="52.5rem">
            <Typography.Paragraph color="white" weight="medium">
              Feel free to reach out to me if you have any question or if you&apos;ll like to create magic with me.
            </Typography.Paragraph>
          </Section.Right>
        </Section>

        <ContactForm />
      </Content>
    </Wrapper>
  );
};

export default ContactMe;
