import * as React from 'react';

function SvgInstagram(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 35 35" fill="none" {...props}>
      <path d="M29.873 0H5.127A5.133 5.133 0 000 5.127v24.746C0 32.7 2.3 35 5.127 35h24.746C32.7 35 35 32.7 35 29.873V5.127C35 2.3 32.7 0 29.873 0zM17.568 26.66c-5.088 0-9.228-4.14-9.228-9.228 0-5.09 4.14-9.229 9.228-9.229 5.09 0 9.229 4.14 9.229 9.229 0 5.088-4.14 9.228-9.229 9.228zm10.254-16.406a3.08 3.08 0 01-3.076-3.076 3.08 3.08 0 013.076-3.076 3.08 3.08 0 013.076 3.076 3.08 3.08 0 01-3.076 3.076z" />
      <path d="M27.822 6.152a1.026 1.026 0 10.001 2.052 1.026 1.026 0 000-2.052zM17.568 10.254c-3.957 0-7.177 3.22-7.177 7.178 0 3.957 3.22 7.177 7.177 7.177 3.958 0 7.178-3.22 7.178-7.177 0-3.958-3.22-7.178-7.178-7.178z" />
    </svg>
  );
}

SvgInstagram.defaultProps = {
  fill: '#fff',
};

export default SvgInstagram;
