import React from 'react';
import styled from '@emotion/styled/macro';
import { Nav, Footer, Loader } from 'components/molecules';
import ContactMe from 'components/organisms/ContactMe';
import AboutMe from 'components/organisms/AboutMe';
import Projects from 'components/organisms/Projects';
import MyProcess from 'components/organisms/MyProcess';
import Testimonials from 'components/organisms/Testimonials';

const PageWrapper = styled.main`
  padding-top: 18rem;

  ${({ theme }) => theme.mq.lg`
  padding-top: 24rem;
  
  `}
`;

const Home = () => {
  return (
    <PageWrapper>
      <Loader />
      <Nav />
      <AboutMe />
      <Projects />
      <MyProcess />
      <Testimonials />
      <ContactMe />
      <Footer />
    </PageWrapper>
  );
};

export default Home;
