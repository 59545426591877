import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Typography, Image } from 'components/atoms';
import { IconPlay } from 'components/icons';
import { Box } from 'rebass';
import { useMeasure } from 'react-use';
import { Section } from './Section';

const Wrapper = styled(Section)`
  &:not(:last-child) {
    margin-bottom: 10rem;
  }

  ${({ theme }) => theme.mq.md`
    &:not(:last-child) {
      margin-bottom: 15rem;
    }
  `}
`;

const Type = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.aquamarine};
  display: inline-block;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin-bottom: 4px;

  ${({ theme }) => theme.mq.xl`
  font-size: ${theme.font.size.medium};
`}
`;

const Role = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.light};

  ${({ theme }) => theme.mq.xl`
  font-size: ${theme.font.size.medium};
  margin-bottom: 3.5rem;
`}
`;

const Title = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.5rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mq.xl`
  margin-bottom: 2rem;
font-size: 3.5rem;
`}
`;

const ProjectLink = styled(Typography.Text)`
  display: block;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin-bottom: 3rem;
  word-break: break-word;
  text-decoration: underline;

  ${({ theme }) => theme.mq.xl`
font-size: ${theme.font.size.medium};
`}
`;

const CaseStudy = styled(Typography.Text)`
  display: block;
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin-bottom: 2.5rem;
  padding-left: 4rem;
  position: relative;
  word-break: break-word;

  &:before {
    background: #fff;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
  }

  ${({ theme }) => theme.mq.xl`
font-size: ${theme.font.size.medium};
`}
`;

const Poster = styled(Image)`
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
  }

  &,
  img {
    max-height: ${({ height }) => height}px;
  }
`;

export const Project = ({ img, link, order, title, type, role }) => {
  const [imageRef, { width }] = useMeasure();

  const height = () => {
    return (width * 62.5) / 100;
  };

  return (
    <Wrapper>
      <Section.Left order={[1, 1, 1, order]}>
        <Type>{type}</Type>
        <Role>{role}</Role>
        <Title>{title}</Title>
        {link?.project && (
          <ProjectLink color="lavenderBlue" as="a" href={link.project} target="_blank" rel="noopener noreferrer">
            View Project
          </ProjectLink>
        )}
        {link?.caseStudy && (
          <CaseStudy color="white" as="a" href={link.caseStudy} target="_blank" rel="noopener noreferrer">
            Read case study
          </CaseStudy>
        )}
        {link?.video && (
          <Box mt="auto">
            <IconPlay width="2.4rem" height="2.4rem" fill="#fff" />
          </Box>
        )}
      </Section.Left>

      <Section.Right>
        <Box ref={imageRef} mb={['2rem', null, null, '0']}>
          <Poster height={height()} src={img} alt={title} />
        </Box>
      </Section.Right>
    </Wrapper>
  );
};

Project.propTypes = {
  img: PropTypes.string.isRequired,
  link: PropTypes.shape({
    caseStudy: PropTypes.string,
    project: PropTypes.string,
    video: PropTypes.string,
  }),
  order: PropTypes.number,
  role: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

Project.defaultProps = {
  link: {
    case: '',
    project: '',
    video: '',
  },
  order: 0,
};
