import React from 'react';
import { Content, Image } from 'components/atoms';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import designProcess from 'assets/img/design-process.png';

const Wrapper = styled.section`
  margin-bottom: 11rem;
`;

const Poster = styled(Image)``;

const MyProcess = (props) => {
  return (
    <Wrapper id="my-process" {...props}>
      <Content>
        <Flex alignItems="center" justifyContent="center">
          <Poster src={designProcess} alt="My design process" />
        </Flex>
      </Content>
    </Wrapper>
  );
};

export default MyProcess;
