import styled from '@emotion/styled/macro';
import { Flex, Box } from 'rebass';

const Section = styled(Flex)`
  flex-wrap: wrap;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  ${({ theme }) => theme.mq.lg`
  margin-left: -3rem;
  margin-right: -3rem;
  `}
`;

const SectionLeft = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;

  ${({ theme }) => theme.mq.lg`
        width: 50%;
        margin-bottom: 0;
    `}

  ${({ theme }) => theme.mq.xl`
        flex-shrink:0;
        flex: 1;
        max-width: 33rem;
        padding-left: 3rem;
        padding-right: 3rem;
    `}
`;

const SectionRight = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;

  ${({ theme }) => theme.mq.lg`
        width: 50%;
    `}

  ${({ theme }) => theme.mq.xl`
    flex: 1;
    max-width: auto;
    padding-left: 3rem;
    padding-right: 3rem;    
`}
`;

Section.Left = SectionLeft;
Section.Right = SectionRight;

export { Section };
