import * as React from 'react';

function SvgDribbble(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 35 35" fill="none" {...props}>
      <path d="M22.765 19.083a70.661 70.661 0 013.82 13.381 17.638 17.638 0 003.29-2.59 17.37 17.37 0 004.884-9.458 21.68 21.68 0 00-8.307-1.646 21.85 21.85 0 00-3.687.313zM29.89 5.14l-.016-.014A17.386 17.386 0 0017.5 0c-1.828 0-3.613.28-5.307.816a70.91 70.91 0 017.15 10.868A40.618 40.618 0 0029.89 5.141zM31.264 6.69a42.363 42.363 0 01-10.974 6.83 71.886 71.886 0 011.674 3.62 23.974 23.974 0 014.488-.422c2.946 0 5.81.53 8.53 1.573A17.368 17.368 0 0031.264 6.69zM6.728 31.295A17.367 17.367 0 0017.5 35c2.513 0 4.945-.527 7.171-1.528a68.779 68.779 0 00-3.952-13.936c-6.193 1.69-11.296 6.049-13.99 11.759z" />
      <path d="M17.17 18.593c.9-.38 1.817-.703 2.749-.969a69.177 69.177 0 00-1.563-3.33A42.348 42.348 0 013.55 16.937c-1.186 0-2.365-.049-3.535-.144a17.385 17.385 0 005.11 13.08l.004.003a23.787 23.787 0 014.46-6.173 23.77 23.77 0 017.58-5.11zM17.397 12.451a69.66 69.66 0 00-7.245-10.843 17.51 17.51 0 00-5.026 3.518A17.368 17.368 0 00.215 14.75c1.1.09 2.212.136 3.335.136 4.86 0 9.525-.86 13.847-2.436z" />
    </svg>
  );
}

SvgDribbble.defaultProps = {
  fill: '#fff',
};

export default SvgDribbble;
