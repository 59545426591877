import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { IconLogo } from 'components/icons';
import { Content, Button } from 'components/atoms';
import { useTransition } from 'react-spring';
import cb from 'bezier-easing';
import * as smoothScroll from 'lib/smoothScroll';
import { Flex } from 'rebass';
import { createBreakpoint } from 'react-use';
import {
  NavWrapper,
  NavBrand,
  NavItem,
  NavLink,
  MobileMenu,
  NavList,
  Hamburger,
  DownloadButtonWrapper,
} from './styled';

export const useBreakpoint = createBreakpoint({
  desktop: 992,
  mobile: 0,
});

const NavMenu = ({ close }) => {
  useLayoutEffect(() => {
    smoothScroll.initialize();
  }, []);

  return (
    <>
      <NavList>
        <NavItem>
          <NavLink onClick={close} href="#about" className="nl">
            About me
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink onClick={close} href="#projects" className="nl">
            Projects
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink onClick={close} href="#my-process" className="nl">
            My process
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink onClick={close} href="#testimonials" className="nl">
            Testimonials
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink onClick={close} href="#contact" className="nl">
            Contact me
          </NavLink>
        </NavItem>
      </NavList>

      <DownloadButtonWrapper as="a" target="_blank" rel="noopener noreferrer" href="/assets/resume.pdf">
        <Button>Download Resume</Button>
      </DownloadButtonWrapper>
    </>
  );
};

export const Nav = () => {
  const [active, setActive] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const breakpoint = useBreakpoint();
  const toggleActive = () => setActive((v) => !v);

  const easing = cb(0.53, -0.19, 0.39, 1.29);
  const transitions = useTransition(active, null, {
    from: { opacity: 0, transform: 'translateX(-100%) ' },
    enter: { opacity: 1, transform: 'translateX(0) ' },
    leave: { opacity: 0, transform: 'translateX(100%)' },
    config: {
      duration: 300,
      easing: (t) => easing(t),
    },
  });

  const scrollToTop = () => {
    if (window !== undefined) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      const noHashURL = window.location.href.replace(/#.*$/, '');
      window.history.replaceState('', document.title, noHashURL);
    }
  };

  useEffect(() => {
    const onScroll = () => {
      const offset = window.pageYOffset;
      setScrolled(offset > 10);
    };

    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <NavWrapper as="nav" scrolled={scrolled}>
      <Content>
        <Flex alignItems="center" justifyContent="space-between">
          <NavBrand onClick={scrollToTop}>
            <IconLogo />
          </NavBrand>

          {breakpoint === 'mobile' ? (
            <>
              {transitions.map(
                ({ item, key, props: { opacity, transform } }) =>
                  item && (
                    <MobileMenu key={key} style={{ transform, opacity }}>
                      <NavMenu close={() => setActive(false)} />
                    </MobileMenu>
                  ),
              )}

              <Hamburger aria-label="Hamburger button" active={active} onClick={toggleActive}>
                <span />
                <span />
                <span />
              </Hamburger>
            </>
          ) : (
            <NavMenu />
          )}
        </Flex>
      </Content>
    </NavWrapper>
  );
};

NavMenu.propTypes = {
  close: PropTypes.func,
};

NavMenu.defaultProps = {
  close: () => {},
};
