import React from 'react';
import styled from '@emotion/styled/macro';
import { Content, Image, Typography } from 'components/atoms';
import { Section } from 'components/molecules';
import designBeast from 'assets/img/design-beast.jpg';
import { Box, Flex } from 'rebass';
import { Banner } from 'components/molecules/Banner';

const Wrapper = styled.div`
  margin-bottom: 15rem;
`;

const Poster = styled(Image)`
  width: 100%;
  height: auto;
`;

const WriteUp = styled(Box)`
  p {
    &.title {
      font-size: ${({ theme }) => theme.font.size.normal};
      font-weight: 500;
    }

    &.subtitle {
      font-size: ${({ theme }) => theme.font.size.small};
      font-weight: 300;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    ${({ theme }) => theme.mq.sm`
        &.title {
            font-size: ${theme.font.size.large};
            font-weight: 500;
        }

        &.subtitle {
            font-size: ${theme.font.size.medium};
            font-weight: 300;
        }

        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    `}
  }
`;

const Featured = styled(Box)`
  .title {
    color: ${({ theme }) => theme.colors.lavenderBlue};
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .subtitle {
    color: ${({ theme }) => theme.colors.grey};
    font-size: 1.2rem;
    font-weight: 500;
  }

  ${({ theme }) => theme.mq.sm`
  .title{
    color: ${theme.colors.lavenderBlue};
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.6rem;
  }


  .subtitle{
    color: ${theme.colors.grey};
    font-size: 1.8rem;
    font-weight: 500;
  }
  `}
`;

const AboutMe = () => {
  return (
    <Wrapper id="about">
      <Content>
        <Banner />
        <Section>
          <Section.Left>
            <Typography.Paragraph color="aquamarine" size="medium" weight="medium">
              About me
            </Typography.Paragraph>
          </Section.Left>
          <Section.Right>
            <Box mb={['4rem', null, '7.5rem']} mx={['-2rem', null, 0]}>
              <Poster src={designBeast} alt="Layor working on a project" />
            </Box>

            <Flex flexWrap="wrap" mx={['-1.5rem', null, null, '-3rem']}>
              <Box width={[1, 1, 1, 7 / 12]} px={['1.5rem', null, null, '3rem']} mb={['3rem', null, null, 0]}>
                <WriteUp>
                  <Typography.Paragraph className="title" size="large" weight="medium">
                    Think of me not just as a freelancer but as a member of your team. I believe in synergy and
                    seemlessly integrate into the workflow of my company or client!
                  </Typography.Paragraph>

                  <Typography.Paragraph className="subtitle" size="medium" weight="light">
                    My name is Salami Mubarak, also known as Layor, and I am a Product Designer with over five years of
                    experience. Driven by empathy and creativity, I thrive on tackling challenging customer problems
                    through thoughtful research and human-centric design. I excel in fast-paced, agile, and
                    collaborative team environments, working closely with both startups and corporate organizations.
                  </Typography.Paragraph>

                  <Typography.Paragraph className="subtitle" size="medium" weight="light">
                    With a deep understanding of both the product owner&apos;s perspective and user needs, I have
                    designed and built a wide range of products, from enterprise systems and corporate websites to
                    Hackathon-winning ideas and mobile apps. My passion for creating usable, inclusive, and accessible
                    experiences is reflected in my work. My passion project, Sharply—a company focused on helping
                    businesses manage their delivery operations, scale their services, and improve efficiency and
                    customer experiences.—showcases my commitment to serving the communities I care deeply about. I look
                    forward to collaborating with others who share my values of inclusivity, empathy, intentionality,
                    and community.
                  </Typography.Paragraph>
                </WriteUp>
              </Box>

              <Box width={[1, 1, 1, 5 / 12]} px={['1.5rem', null, null, '3rem']}>
                <Flex mx="-1.5rem" flexWrap="wrap">
                  <Box px="1.5rem" width={[1 / 2, null, null, 1]} mb={[0, null, null, '3rem']}>
                    <Featured>
                      <Typography.Paragraph className="title">Tools I use:</Typography.Paragraph>
                      <Typography.Paragraph className="subtitle">
                        Figma / Sketch / InVision / Adobe Xd / After effect / Zeplin / Mavel App / Photoshop
                      </Typography.Paragraph>
                    </Featured>
                  </Box>

                  <Box px="1.5rem" width={[1 / 2, null, null, 1]}>
                    <Featured>
                      <Typography.Paragraph className="title">My Skills:</Typography.Paragraph>
                      <Typography.Paragraph className="subtitle">
                        UI Design / UX Design / User Testing / UX Reserch/ Poduct magement
                      </Typography.Paragraph>
                    </Featured>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Section.Right>
        </Section>
      </Content>
    </Wrapper>
  );
};

export default AboutMe;
