import React from 'react';
import { Content } from 'components/atoms';
import { Box, Flex } from 'rebass';
import { IconInstagram, IconLinkedin, IconTwitter, IconDribbble } from 'components/icons';
import { FooterWrapper, FooterHeader, Copyright, Socials, SocialItem, SocialLink } from './styled';

export const Footer = () => {
  return (
    <FooterWrapper as="footer">
      <Content>
        <Flex flexWrap="wrap" mx="-2.5rem" alignItems="center" mb={['3rem', '3rem', '10rem']}>
          <Box px="2.5rem" width={[1, 1, 1 / 2]} mb={['3rem', '3rem', 0]}>
            <FooterHeader>Incase you want to catch up with me</FooterHeader>
          </Box>
          <Box px="2.5rem" width={[1, 1, 1 / 2]}>
            <Socials>
              <SocialItem>
                <SocialLink aria-label="Instagram Link" href="https://www.instagram.com/6bops">
                  <IconInstagram />
                </SocialLink>
              </SocialItem>

              <SocialItem>
                <SocialLink aria-label="Linkedin Link" href="https://www.linkedin.com/in/salami-mubarak-14634813b">
                  <IconLinkedin />
                </SocialLink>
              </SocialItem>

              <SocialItem>
                <SocialLink aria-label="Twitter Link" href="https://twitter.com/6bops">
                  <IconTwitter />
                </SocialLink>
              </SocialItem>

              <SocialItem>
                <SocialLink aria-label="Dribbble Link" href="https://dribbble.com/Layor">
                  <IconDribbble />
                </SocialLink>
              </SocialItem>
            </Socials>
          </Box>
        </Flex>

        <Box display="flex" justifyContent="center">
          <Copyright>© 2020 Salami Mubarak. All rights reserved Designed with Figma.</Copyright>
        </Box>
      </Content>
    </FooterWrapper>
  );
};
